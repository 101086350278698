import React from "react";
import { ApiKeys } from "./ApiKeys";

const Settings = () => {
  return (
    <div>
      <ApiKeys />
    </div>
  );
};

export default Settings;
